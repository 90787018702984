<template>
  <div id="app">
    <div class="principalImg">
      <div class="container">
        <div class="">
          <div class="presonus-logo d-none d-sm-block">
            <img
              class="img-fluid"
              src="./assets/presonus logo blanco 505.png"
              alt="presonus"
            />
            <div class="presonusTour d-flex justify-content-end">Tour 2022</div>
          </div>
          <div class="presonus-logo-responsive d-block d-sm-none">
            <img
              class="img-fluid"
              src="./assets/logo_presonus_logo_7442.jpg"
              alt="presonus"
            />
            <div class="presonusTour d-flex justify-content-end">Tour 2022</div>
          </div>
          <div class="main">
            <div class="content">
              <div class="principal">
                Lo elemental que necesitas saber sobre grabación y mezcla
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="principal-color py-5">
      <h2 class="text-center principal2 py-5 mx-5 mx-md-0">
        Participa del Workshop gratuito sobre Producción Musical y Audio
        Digital!
      </h2>
    </div>

    <div class="details-background">
      <div class="container">
        <div class="row" style="height: 800px">
          <div class="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 my-auto">
            <div>
              <p class="details">
                Ven a pasar el rato junto a
                <span class="fw-bold">Gus Lozada</span> en una clínica para
                hablar sobre lo elemental del sonido, la producción y la mezcla.
                Además, los aspectos más importantes para
                <span class="fw-bold">montar un Home Studio</span>
                según los diferentes propósitos (grabar música, hacer
                LiveStreams o PodCasts) y
                <span class="fw-bold">
                  para llevar un show del estudio al escenario.
                </span>
              </p>
            </div>

            <div>
              <span class="white--text details">
                ¡Acompañanos! Además participaras del SORTEO por importantes
                premios ¡Conocélos más abajo!
              </span>
            </div>
          </div>

          <div class="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 my-auto">
            <div class="d-flex my-md-3">
              <img
                class="mr-5"
                width="15%"
                src="./assets/Gus_Lozada_disertante.png"
                alt="Lozada"
              />
              <div class="align-self-center">
                <span class="contentInfo"> Gus Lozada </span>
                <span class="contentInfo"> Ing. especialista PreSonus </span>
              </div>
            </div>

            <div class="d-flex my-md-3">
              <img
                class="mr-5"
                width="15%"
                src="./assets/elementos gráficos_lp/calendario_wh.png"
                alt="Calendario"
              />
              <div class="d-flex flex-column align-self-center">
                <span class="contentInfo"> Jueves 30/6 </span>
              </div>
            </div>

            <div class="d-flex my-md-3">
              <img
                class="mr-5"
                width="15%"
                src="./assets/elementos gráficos_lp/horario_wh.png"
                alt="time"
              />

              <div class="d-flex flex-column align-self-center">
                <span class="contentInfo"> 17:00 a 20:00hs </span>
              </div>
            </div>

            <div class="d-flex my-3">
              <img
                class="mr-5"
                width="15%"
                height="40%"
                src="./assets/elementos gráficos_lp/locacion_wh.png"
                alt="Adress"
              />
              <div class="d-flex flex-column align-self-center">
                <span class="contentInfo">
                  Sala Gastón Barral, Edificio CIFIC
                </span>
                <span class="contentInfo"> Rawson 42, Almagro </span>
                <span class="contentInfo">
                  Esquina Av. Rivadavia (alt. 4100), CABA
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="principal-color-2">
      <ValidationObserver ref="obs" v-slot="{ passes }">
        <div class="container py-5">
          <div class="button mb-5">
            <img
              style="transform: rotate(90deg)"
              width="50"
              src="./assets/elementos gráficos_lp/arrow_wh.png"
              alt=""
            />
            REGISTRATE
          </div>
          <div class="row">
            <p class="placeholder-glow" v-if="loading">
              <span class="placeholder col-12 col-md-6"></span>
              cargando...
            </p>

            <div class="col-12 col-md-6" v-else>
              <ValidationProvider
                name="Nombre y Apellido"
                rules="required"
                v-slot="{ errors }"
              >
                <input
                  v-model="first_name"
                  placeholder="Nombre y Apellido*"
                  type="text"
                  id="name"
                  class="form-control custom-input-color"
                  aria-describedby="name"
                />
                <small class="invalid-validation">{{ errors[0] }}</small>
              </ValidationProvider>

              <ValidationProvider
                name="DNI"
                rules="required|numeric|min:0|max:8"
                v-slot="{ errors }"
              >
                <input
                  v-model="doc_number"
                  placeholder="DNI*"
                  type="text"
                  id="name"
                  class="form-control custom-input-color my-3"
                  aria-describedby="name"
                />
                <small class="invalid-validation">{{ errors[0] }}</small>
              </ValidationProvider>

              <ValidationProvider
                name="MAIL"
                rules="email|required|confirmed:email_confirmation"
                v-slot="{ errors }"
              >
                <input
                  v-model="email"
                  placeholder="MAIL*"
                  type="text"
                  id="name"
                  class="form-control custom-input-color my-3"
                  aria-describedby="name"
                />
                <small class="invalid-validation">{{ errors[0] }}</small>
              </ValidationProvider>

              <ValidationProvider
                name="email confirmacion"
                rules="email|required"
                v-slot="{ errors }"
                vid="email_confirmation"
              >
                <input
                  v-model="email_again"
                  placeholder="Ingrese su MAIL* nuevamente"
                  type="text"
                  id="name"
                  class="form-control custom-input-color my-3"
                  aria-describedby="name"
                />
                <small class="invalid-validation">{{ errors[0] }}</small>
              </ValidationProvider>

              <ValidationProvider
                name="telefono"
                rules="alpha_num|max:9"
                v-slot="{ errors }"
              >
                <input
                  v-model="phone"
                  placeholder="Te enviaremos recordatorio por Whatsapp"
                  type="text"
                  id="name"
                  class="form-control custom-input-color my-3"
                  aria-describedby="name"
                />
                <small class="invalid-validation">{{ errors[0] }}</small>
              </ValidationProvider>

              <ValidationProvider
                name="conocenos"
                rules="alpha"
                v-slot="{ errors }"
              >
                <input
                  v-model="know"
                  placeholder="¿Como se entero del evento?"
                  type="text"
                  id="name"
                  class="form-control custom-input-color my-3"
                  aria-describedby="name"
                />
                <small class="invalid-validation">{{ errors[0] }}</small>
              </ValidationProvider>

              <div class="d-grid gap-2">
                <div
                  class="
                    btn btn-light
                    send-button
                    d-flex
                    justify-content-center
                  "
                  @click="passes(registerForm)"
                >
                  <img
                    class="img-fluid"
                    width="40px"
                    src="../src/assets/elementos gráficos_lp/arrow_vl.png"
                    alt=""
                  />
                  <div class="pt-1">Enviar</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>

    <div class="principal-3 d-flex flex-column">
      <div class="my-auto">
        <div class="text-center sorteo">SORTEO</div>
        <div class="text-center sorteo">Importantes regalos</div>
        <div class="text-center sorteo2">Que podrás elegir según</div>
        <div class="text-center sorteo2">Tu conveniencia</div>
      </div>
    </div>

    <div class="principal-4">
      <div class="container">
        <div class="row justify-content-between">
          <div
            class="
              col-9 col-md-6 col-lg-3 col-xl-3 col-xxl-3
              d-flex
              flex-column
              py-5 py-md-0
              mx-auto
            "
          >
            <div class="mt-auto">
              <img
                class="img-fluid"
                src="./assets/624_sorteo_eris_e35-02.webp"
                alt="sorteo1"
              />
            </div>
            <div class="mt-3">
              <span class="principal-color-text fw-bold">
                Monitores Eris E3.5
              </span>
              <span>Active Media</span>
            </div>
          </div>

          <div
            class="
              col-9 col-md-6 col-lg-3 col-xl-3 col-xxl-3
              d-flex
              flex-column
              py-5 py-md-0
              mx-auto
            "
          >
            <div class="mt-auto">
              <img
                class="img-fluid"
                src="./assets/624sorteo_ioStation24c-02a.webp"
                alt="sorteo2"
              />
            </div>

            <div class="mt-auto text-center">
              <span class="principal-color-text fw-bold"> ioStation 24c </span>
              <span>
                Interfaz de audio y controlador de producción 2x2 compatible con
                USB-C
              </span>
            </div>
          </div>

          <div
            class="
              col-9 col-md-6 col-lg-3 col-xl-3 col-xxl-3
              d-flex
              flex-column
              mx-auto
            "
          >
            <div class="mt-auto">
              <img
                class="img-fluid"
                src="./assets/624_sorteo_studio_26c-05.webp"
                alt="sorteo3"
              />
            </div>

            <div class="mt-auto text-center">
              <span class="principal-color-text fw-bold"> Studio 26c </span>
              <span>
                La interfaz de audio portátil de ultra alta definición
                compatible con USB-C
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="principal-5 mb-0 pb-0">
      <div class="container">
        <div class="text-center event pt-5">
          A quién está destinado este evento?
        </div>
        <div class="row justify-content-center">
          <div class="col-10 col-md-5 my-5">
            <ul class="ulStyle">
              <li>Estudiantes de sonido</li>
              <li>Músicos</li>
              <li>Locutores</li>
              <li>Artistas Sonoros</li>
              <li>Artistas</li>
              <li>Streamers y PodCasters</li>
              <li>Sonidistas</li>
              <li>Realizadores Audiovisuales</li>
              <li>Editores de Video</li>
              <li>Editores de Radio</li>
              <li>Profesionales de Medio</li>
              <li>Diseñadores y Comunicadores audiovisuales</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="my-5">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="d-flex justify-content-start">
              <img
                width="15%"
                src="./assets/elementos gráficos_lp/calendario_bl.png"
                alt="Calendario"
              />
              <span class="my-auto principal-color-text-2">Jueves 30/6 </span>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="d-flex justify-content-start">
              <img
                width="15%"
                src="./assets/elementos gráficos_lp/horario_bl.png"
                alt="Calendario"
              />
              <span class="my-auto principal-color-text-2"
                >17:00 a 20:00hs
              </span>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="d-flex justify-content-start">
              <img
                width="15%"
                height="15%"
                src="./assets/elementos gráficos_lp/locacion_bl.png"
                alt="Calendario"
              />
              <div class="d-flex justify-content-center">
                <div class="my-auto">
                  <span class="principal-color-text-2 fw-bold-2">
                    Sala Gastón Barral
                  </span>
                  <br />
                  Edificio CIFIC
                  <br />
                  <span class="principal-color-text-2 fw-bold-2">
                    Rawson 42, Almagro
                  </span>
                  <br />
                  <span class="fst-italic"
                    >Esquina Av. Rivadavia (alt. 4100), CABA
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="d-flex justify-content-start">
              <a
                href="https://www.instagram.com/presonus.argentina/"
                target="_blank"
                style="text-decoration: none"
              >
                <img
                  width="15%"
                  src="./assets/elementos gráficos_lp/instagram_bl.png"
                  alt="Calendario"
                />
                <span class="my-auto principal-color-text-2 fst-italic">
                  @presonus.argentina
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container pt-5">
      <div class="row">
        <div class="col-12 col-md-6">
          <a href="https://www.stadio.com.ar/" target="_blank">
            <img
              width="50%"
              src="./assets/logo_stadio.svg"
              alt="logoStadio"
              class="img-fluid"
            />
          </a>
          <p class="mb-0 mt-3">Organiza e invita al Workshop, Stadio,</p>
          <p>representante de PreSonus en Argentina.</p>
        </div>
        <div class="col-12 col-md-6">
          <div class="d-flex justify-content-md-end">
            <a href="http://www.sate.org.ar/" target="_blank">
              <img
                width="68.94"
                height="68.94"
                src="./assets/logo_sate.png"
                alt="logoState"
              />
            </a>
          </div>
          <p class="text-md-end mt-3 mb-0">
            Agradecemos al Sindicato de Técnicos
          </p>
          <p class="text-md-end">escénicos por su Patrocinio.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "App",
  data() {
    return {
      loading: false,
      email: "",
      email_again: "",
      doc_type: "",
      doc_number: "",
      phone: "",
      first_name: "",
      last_name: "",
      know: "",
    };
  },
  methods: {
    async registerForm() {
      try {
        const request = {
          email: this.email,
          store_id: 12,
          doc_type: "DNI",
          doc_number: this.doc_number,
          phone: this.phone,
          first_name: `${this.first_name} ${this.last_name}`,
          last_name: ".",
          description: this.know,
        };

        await axios.post(
          "https://ventas.flextd.com/api/subscriptions/email_v2",
          request
        );

        this.$toast.open({
          message: "Registro Exitoso!",
          type: "sucess",
          position: "top",
        });

        this.$refs.obs.reset();

        this.email = "";
        this.doc_number = "";
        this.phone = "";
        this.first_name = "";
        this.last_name = "";
        this.email_again = "";
        this.know = "";
      } catch (error) {
        this.$toast.open({
          message: "Ha ocurrido un error intente mas tarde",
          type: "error",
          // all of other options may go here
        });
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap");

* {
  font-family: "Lato", sans-serif;
}
.principalImg {
  background-image: url("./assets/principal.webp");
  width: 100%;
  height: 800px;
  background-repeat: no-repeat, repeat;
  background-size: cover;
}

.presonus-logo {
  margin-left: auto;
  width: 400px;
  padding-top: 10%;
}

.presonus-logo-responsive {
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  padding-top: 10%;
}

.presonusTour {
  color: #bebebe;
  font-size: 2em;
  font-weight: 400;
  margin-left: auto;
}

.main {
  position: relative;
  height: 500px;
}

.content {
  position: absolute;
  bottom: 0;
  align-items: center;
  text-align: center;
}

.principal {
  font-weight: 300;
  font-size: 10vmin;
  color: white;
}

.principal2 {
  margin: 0;
  color: white;
  font-weight: 300;
  padding-top: 50px;
  padding-bottom: 50px;
  line-height: 50px;
}

.principal-color {
  background-color: #28024c;
}

.details-background {
  background-image: url("./assets/principal2.webp");
  width: 100%;
  height: 800px;
  background-repeat: no-repeat, repeat;
  background-size: cover;
}

.details {
  font-size: calc(1.3rem + 0.6vw);
  font-weight: 300;
  color: white;
}

.contentInfo {
  font-size: calc(1rem + 0.4vw);
  color: white;
  font-weight: 300;
}

.button {
  cursor: pointer;
  color: white;
  font-size: 50px;
  font-weight: 200;
}

.custom-input-color {
  background-color: #716585;
  border-radius: 0;
  border-color: rgb(0, 0, 84);
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1;
  /* Firefox */
}

.principal-color-2 {
  background-color: rgb(0, 0, 84);
}

.principal-color-text {
  color: rgb(0, 0, 84);
  font-size: 1.1em;
  font-weight: bold;
}

.principal-color-text-2 {
  color: rgb(0, 0, 84);
  font-size: 1.2em;
  font-weight: 400;
}

.principal-3 {
  background-image: url("./assets/img03_02_mediabar_sorteo.webp");
  width: 100%;
  height: 620px;
  background-repeat: no-repeat, repeat;
}

.sorteo {
  font-size: 9vmin;
  color: white;
  font-weight: 400;
}

.sorteo2 {
  font-size: 8vmin;
  color: white;
  font-weight: 300;
}

.principal-4 {
  background-color: white;
  margin-top: 5%;
  margin-bottom: 5%;
}

.principal-5 {
  background-color: rgb(0, 0, 84);
  margin: 0;
}

.event {
  color: white;
  font-size: 5.25vmin;
}

.ulStyle {
  color: white;
  font-size: calc(1.3rem + 0.6vw);
  font-weight: 300;
}

.fw-bold-2 {
  font-weight: 600;
}

@media (max-width: 980px) and (min-width: 600px) {
  .main {
    position: relative;
    height: 600px;
  }
}

.invalid-validation {
  color: white;
}

.send-button {
  color: indigo;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
  background-color: white;
  border-radius: 30px;
  border: 2px solid gray;
  padding: 0;
}

/* @media (max-width: 980px) and (min-width: 770px) {
  .main {
    position: relative;
    height: 600px;
  }
} */
</style>
