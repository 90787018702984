import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import vspanish from 'vee-validate/dist/locale/es';

for (let rule in rules) {
  extend(rule, {
    ...rules[rule],
    message: vspanish.messages[rule]
  });
}

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.use(VueToast);

new Vue({
  render: h => h(App),
}).$mount('#app')
